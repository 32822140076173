import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { 
    path: '', 
    loadChildren: () => import('./walkthrough/walkthrough.module').then(m => m.WalkthroughPageModule) 
  },
  { 
    path: 'app', 
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule) 
  },
  { 
    path: 'getting-started', 
    loadChildren: () => import('./getting-started/getting-started.module').then(m => m.GettingStartedPageModule) 
  },
  { 
    path: 'product', 
    loadChildren: () => import('./products/products.module').then(m => m.ProductsPageModule) 
  },
  { 
    path: 'auth/forgot-password', 
    loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule) 
  },
  { 
    path: 'contact-card', 
    loadChildren: () => import('./contact-card/contact-card.module').then(m => m.ContactCardPageModule) 
  },
  { 
    path: 'forms-and-validations', 
    loadChildren: () => import('./forms/validations/forms-validations.module').then(m => m.FormsValidationsPageModule) 
  },
  { 
    path: 'forms-filters', 
    loadChildren: () => import('./forms/filters/forms-filters.module').then(m => m.FormsFiltersPageModule) 
  },
  { 
    path: 'page-not-found', 
    loadChildren: () => import('./page-not-found/page-not-found.module').then(m => m.PageNotFoundModule) 
  },
  { 
    path: 'showcase', 
    loadChildren: () => import('./showcase/showcase.module').then(m => m.ShowcasePageModule) 
  },
  { 
    path: 'firebase', 
    loadChildren: () => import('./firebase/firebase-integration.module').then(m => m.FirebaseIntegrationModule) 
  },
  { 
    path: 'maps', 
    loadChildren: () => import('./maps/maps.module').then(m => m.MapsPageModule) 
  },
  { 
    path: '**', redirectTo: 'page-not-found' 
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
