import { Component } from '@angular/core';
import { Plugins } from '@capacitor/core';
const { SplashScreen } = Plugins;
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import {  Platform, NavController } from '@ionic/angular';
import { Router } from '@angular/router';

import { Storage } from '@ionic/storage';
import { WalkthroughPageModule } from '../app/walkthrough/walkthrough.module';
import { StorageService } from '../app/services/storage.service';
import { AuthConstants } from '../app/config/auth-constants';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: [
    './side-menu/styles/side-menu.scss',
    './side-menu/styles/side-menu.shell.scss',
    './side-menu/styles/side-menu.responsive.scss'
  ]
})
export class AppComponent {
  appPages = [
    {
      title: 'Vecindario',
      url: '/app/cercanos',
      visible: true,
      ionicIcon: 'list-outline'
    },
    {
      title: 'Mi Perfil',
      url: '/app/user',
      visible: false,
      ionicIcon: 'person-outline'
    },
    // {
    //   title: 'Contact Card',
    //   url: '/contact-card',
    //   customIcon: './assets/custom-icons/side-menu/contact-card.svg'
    // },
    {
      title: 'Favoritos',
      url: '/app/notifications',
      visible: false,
      ionicIcon: 'heart'
    },
    {
      title: 'Crear producto',
      url: '/product',
      visible: false,
      ionicIcon: 'hammer'
    }
  ];

  textDir = 'ltr';

  loggedIn = false;

  constructor(public translate: TranslateService, 
    private platform: Platform,
    private router: Router, 
    private storage: Storage, 
    private storageService: StorageService) {
    this.initializeApp();
    this.setLanguage();
    storageService.get(AuthConstants.AUTH).then(res => {
      if(!!res){
        this.loggedIn = true;
      } else {
        this.loggedIn = false;
      }
    });
  }

  async initializeApp() {
    try {
     await SplashScreen.hide();
    } catch (err) {
     console.log('This is normal in a browser', err);
    }
  }

  async ngOnInit() {
    this.listenForLoginEvents();
  }

  setLanguage() {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use('en');

    // this is to determine the text direction depending on the selected language
    // for the purpose of this example we determine that only arabic and hebrew are RTL.
    // this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    //   this.textDir = (event.lang === 'ar' || event.lang === 'iw') ? 'rtl' : 'ltr';
    // });
  }

  listenForLoginEvents() {
    window.addEventListener('user:login', () => {
      this.loggedIn = true;
    });

    window.addEventListener('user:signup', () => {
      this.loggedIn = true;
    });

    window.addEventListener('user:logout', () => {
      this.loggedIn = false;
    });
  }

  logout() {
    this.storageService.removeStorageItem(AuthConstants.AUTH);
    window.dispatchEvent(new CustomEvent('user:logout'));
  }


}
