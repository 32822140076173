import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  //Storage a value
  async store(storageKey: string, value: any) {
    const encryptedValue = btoa(escape(JSON.stringify(value)));
    await Storage.set({ 
      key: storageKey,
      value: encryptedValue
    });
  }

  // Get a value
  async get(storageKey: string) {
    try {
      const ret = await Storage.get({ key: storageKey });    
      return JSON.parse(unescape(atob(ret.value)));
     } catch (err) {

     }    
  }
   
  async removeStorageItem(storageKey: string) {
    await Storage.remove({ key: storageKey });
  }
  
  // Clear storage
  async clear() {
    await Storage.clear();
  }
}
